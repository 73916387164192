var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
export var EnquiryForm = function (_a) {
    var successUrl = _a.successUrl, captcha = _a.captcha;
    var _b = useState(''), name = _b[0], setName = _b[1];
    var _c = useState(''), emailAddress = _c[0], setEmailAddress = _c[1];
    var _d = useState(''), enquiry = _d[0], setEnquiry = _d[1];
    var _e = useState(''), sum = _e[0], setSum = _e[1];
    var _f = useState(false), isFormValid = _f[0], setIsFormValid = _f[1];
    var _g = useState(false), emailAddressTouched = _g[0], setEmailAddressTouched = _g[1];
    var _h = useState(false), emailAddressFocused = _h[0], setEmailAddressFocused = _h[1];
    var handleEmailInputChange = function (e) {
        var newEmailAddress = e.target.value;
        setEmailAddress(newEmailAddress);
    };
    var handleEmailBlur = function () {
        setEmailAddressTouched(true);
        setEmailAddressFocused(false);
    };
    var handleEmailFocus = function () {
        setEmailAddressFocused(true);
    };
    var handleNameInputChange = function (e) {
        var newName = e.target.value;
        setName(newName);
    };
    var handleEnquiryInputChange = function (e) {
        var newEnquiry = e.target.value;
        setEnquiry(newEnquiry);
    };
    var handleSumInputChange = function (e) {
        var newSum = e.target.value;
        setSum(newSum);
    };
    var isValidEmailString = function (emailString) {
        return /^.+@.+\..+$/.test(emailString);
    };
    var isValidNameString = function (nameString) {
        return /^.+$/.test(nameString);
    };
    var isValidEnquiryString = function (enquiryString) {
        return /^.+$/.test(enquiryString);
    };
    var isValidSumString = function (sumString) {
        return /^[0-9]+$/.test(sumString);
    };
    useEffect(function () {
        var isEmailValid = !emailAddress || isValidEmailString(emailAddress); // allow empty email field, otherwise must be valid
        var isNameValid = isValidNameString(name);
        var isEnquiryValid = isValidEnquiryString(enquiry);
        var isSumValid = isValidSumString(sum);
        var isCorrectSum = isSumValid && parseInt(sum) === captcha.correctSum;
        setIsFormValid(isEmailValid &&
            isNameValid &&
            isEnquiryValid &&
            isSumValid &&
            isCorrectSum);
    }, [emailAddress, name, enquiry, sum, captcha]);
    return (_jsx(_Fragment, { children: _jsxs("form", __assign({ name: "enquiry-form", method: "post", action: successUrl }, { children: [_jsx("input", { type: "hidden", name: "form-name", value: "enquiry-form" }), _jsx("br", {}), _jsxs("div", __assign({ className: "field" }, { children: [_jsx("label", __assign({ className: "label is-left" }, { children: "Name" })), _jsx("input", { name: "name", className: "input is-medium", type: "text", placeholder: "Sarah", value: name || '', onChange: handleNameInputChange })] })), _jsxs("div", __assign({ className: "field" }, { children: [_jsx("label", __assign({ className: "label is-left" }, { children: "Email address (optional)" })), _jsx("input", { name: "email", className: "input is-medium", type: "email", placeholder: "sarah@gmail.com", value: emailAddress || '', onChange: handleEmailInputChange, onBlur: handleEmailBlur, onFocus: handleEmailFocus }), emailAddressTouched &&
                            !emailAddressFocused &&
                            emailAddress &&
                            !isValidEmailString(emailAddress) && (_jsx("p", __assign({ className: "help is-danger" }, { children: "Email address is invalid" })))] })), _jsxs("div", __assign({ className: "field" }, { children: [_jsx("label", __assign({ className: "label is-left" }, { children: "Suggestion / Feedback" })), _jsx("textarea", { name: "feedback", className: "textarea has-fixed-size is-medium", placeholder: "What features would you like to see us build next?", value: enquiry || '', onChange: handleEnquiryInputChange })] })), _jsx("br", {}), _jsxs("div", __assign({ className: "columns is-vcentered is-mobile" }, { children: [_jsx("div", __assign({ className: "column is-one-quarter" }, { children: "".concat(captcha.randNum1, " + ").concat(captcha.randNum2, " = ") })), _jsx("div", __assign({ className: "column" }, { children: _jsx("div", __assign({ className: "field" }, { children: _jsx("input", { name: "sum", className: "input", type: "text", value: sum || '', onChange: handleSumInputChange }) })) })), _jsx("div", __assign({ className: "column" }, { children: _jsx("div", __assign({ className: "field" }, { children: _jsx("div", __assign({ className: "control" }, { children: _jsx("button", __assign({ className: "button", type: "submit", disabled: !isFormValid }, { children: "Send enquiry" })) })) })) }))] }))] })) }));
};

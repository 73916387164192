var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ScreenType } from '../../content';
import { SectionBase } from './SectionBase';
import { SectionLists } from './SectionLists';
import { SectionText } from './SectionText';
import { SectionTiles } from './SectionTiles';
export var SectionContainer = function (_a) {
    var isNavBarFixed = _a.isNavBarFixed, screenSection = _a.screenSection, isPrimaryColorScheme = _a.isPrimaryColorScheme, isNotLast = _a.isNotLast;
    var slug = screenSection.slug;
    var renderSection = function (screenSection) {
        switch (screenSection.type) {
            case ScreenType.LISTS:
                return _jsx(SectionLists, { listsSection: screenSection });
            case ScreenType.TEXT:
                return _jsx(SectionText, { textSection: screenSection });
            case ScreenType.TILE:
                return _jsx(SectionTiles, { tileSection: screenSection });
            default:
                return _jsx(SectionBase, { baseSection: screenSection });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "".concat(isNavBarFixed ? 'anchor-fixed-nav' : 'anchor'), id: slug }), _jsx("section", __assign({ className: "hero ".concat(isPrimaryColorScheme ? 'is-primary ' : 'is-secondary ', " is-fullheight").concat(isNavBarFixed ? '-with-navbar' : '') }, { children: renderSection(screenSection) }))] }));
};

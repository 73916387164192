var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var IconInstagram = function (_a) {
    var size = _a.size, url = _a.url;
    return (_jsx("a", __assign({ href: url, target: "_blank", rel: "noreferrer" }, { children: _jsx("div", __assign({ className: "icon ".concat(size) }, { children: _jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100", width: "100%", height: "100%" }, { children: [_jsx("circle", { className: "icon-circle-instagram", cx: "50", cy: "50", r: "50" }), _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100", width: "100%", height: "100%", x: "25", y: "25" }, { children: _jsx("path", { className: "icon-path-instagram", d: "M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z" }) }))] })) })) })));
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from '../molecules';
import { SectionContainer, SectionHero } from '../organisms';
import { content } from '../../content';
export var PageSections = function (_a) {
    var page = _a.page;
    var _b = useLocation(), pathname = _b.pathname, hash = _b.hash, key = _b.key;
    var name = content.name, isNavBarFixed = content.config.isNavBarFixed;
    var title = page.title, description = page.description, hero = page.hero, sections = page.sections;
    useEffect(function () {
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        else {
            setTimeout(function () {
                var id = hash.replace('#', '');
                var element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [pathname, hash, key, isNavBarFixed]);
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { title: "".concat(title, " | ").concat(name), description: description }), _jsx(SectionHero, { hero: hero }), sections.map(function (section, index) {
                var maxIndex = sections.length - 1;
                return (_jsx(SectionContainer, { isNavBarFixed: isNavBarFixed, screenSection: section, isPrimaryColorScheme: index % 2 !== 0, isNotLast: index !== maxIndex }, "".concat(index, "-").concat(section.slug)));
            })] }));
};

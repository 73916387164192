var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { SocialLinks } from './SocialLinks';
export var Footer = function (_a) {
    var siteTitle = _a.siteTitle, footerLinks = _a.footerLinks;
    var year = new Date().getFullYear();
    return (_jsx("footer", __assign({ className: "footer" }, { children: _jsxs("div", __assign({ className: "content has-text-centered" }, { children: [_jsx(SocialLinks, { size: 'is-large' }), _jsx("br", {}), _jsx("br", {}), footerLinks.map(function (link, index) { return (_jsx(Link, __assign({ to: link.url }, { children: link.name }), "footer-link-".concat(index, "-").concat(link.name))); }), _jsx("br", {}), _jsx("br", {}), _jsxs("p", { children: ["\u00A9 ", year, " ", _jsx("strong", { children: siteTitle })] }), _jsxs("p", { children: ["This tool is intended for illustrative purposes only. It does not constitute financial advice. ", _jsx("br", {}), "Always do your own research."] })] })) })));
};

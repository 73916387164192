var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
export var Tile = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, ctaText = _a.ctaText, ctaUrl = _a.ctaUrl, children = _a.children;
    return (_jsx("div", __assign({ className: "tile is-parent" }, { children: _jsxs("article", __assign({ className: "tile is-child box" }, { children: [_jsx("p", __assign({ className: "title" }, { children: title })), _jsx("div", __assign({ className: "content" }, { children: children })), ctaText && ctaUrl && (_jsx(Link, __assign({ to: ctaUrl, className: "button" }, { children: ctaText })))] })) })));
};

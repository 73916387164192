var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatGBPString } from '@mymoneyuk/shared-utils';
export var Graph = function (_a) {
    var _b;
    var status = _a.status, graphData = _a.graphData;
    var formattedLabels = (_b = graphData === null || graphData === void 0 ? void 0 : graphData.labels) === null || _b === void 0 ? void 0 : _b.map(function (label) {
        return formatGBPString(label);
    });
    return (_jsxs(_Fragment, { children: [(status === 'loading' ||
                status === 'failed' ||
                (status === 'idle' && !(graphData === null || graphData === void 0 ? void 0 : graphData.datasets))) && (_jsx(Line, { options: options, data: { datasets: [] } })), status === 'idle' && (graphData === null || graphData === void 0 ? void 0 : graphData.datasets) && (_jsx(Line, { options: options, data: __assign(__assign({}, graphData), { labels: formattedLabels }) }))] }));
};
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
ChartJS.register({
    id: 'uniqueid5',
    afterDraw: function (chart, easing) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            var activePoint = chart.tooltip._active[0];
            var ctx = chart.ctx;
            var x = activePoint.element.x;
            var topY = chart.scales.y.top;
            var bottomY = chart.scales.y.bottom;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#e23fa9';
            ctx.stroke();
            ctx.restore();
        }
    },
});
export var options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'UK Investment Broker Annual Platform Fees',
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    var label = context.dataset.label || '';
                    if (context.parsed.y !== null && parseFloat(context.parsed.y)) {
                        return "".concat(label, " \u00A3").concat(parseFloat(context.parsed.y).toFixed(2));
                    }
                    return label;
                },
            },
        },
    },
    scales: {
        y: {
            ticks: {
                callback: function (value, index, ticks) {
                    return '£' + value;
                },
            },
        },
        x: {
            ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
            },
        },
    },
};

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export var ScrollToTop = function () {
    var _a = useLocation(), hash = _a.hash, pathname = _a.pathname;
    useEffect(function () {
        if (hash === '') {
            window.scrollTo(0, 0);
        }
    }, [hash, pathname]);
    return null;
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var validateQueryStringParameters = function (schema, parameters) {
    var _a;
    var _b = schema.validate(parameters, { abortEarly: false }), error = _b.error, value = _b.value;
    var errorMessages = (_a = error === null || error === void 0 ? void 0 : error.details.reduce(function (acc, curr) {
        acc.push(curr.message);
        return acc;
    }, [])) !== null && _a !== void 0 ? _a : [];
    if (error) {
        throw new ValidationError(errorMessages);
    }
    return value;
};
var ValidationError = /** @class */ (function (_super) {
    __extends(ValidationError, _super);
    function ValidationError(messages) {
        var _this = _super.call(this) || this;
        _this.messages = messages;
        return _this;
    }
    return ValidationError;
}(Error));
export { ValidationError };
export var getRequestLogObject = function (event, context) {
    var _a, _b, _c, _d;
    return {
        host: (_a = event === null || event === void 0 ? void 0 : event.headers) === null || _a === void 0 ? void 0 : _a.host,
        referer: (_b = event === null || event === void 0 ? void 0 : event.headers) === null || _b === void 0 ? void 0 : _b.referer,
        path: event === null || event === void 0 ? void 0 : event.path,
        method: event === null || event === void 0 ? void 0 : event.httpMethod,
        queryStringParameters: event === null || event === void 0 ? void 0 : event.queryStringParameters,
        platform: (_c = event === null || event === void 0 ? void 0 : event.headers) === null || _c === void 0 ? void 0 : _c['sec-ch-ua-platform'],
        country: (_d = event === null || event === void 0 ? void 0 : event.headers) === null || _d === void 0 ? void 0 : _d['x-country'],
        clientContext: context.clientContext,
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PageType } from '../../content';
export var NavBar = function (_a) {
    var fixed = _a.fixed, content = _a.content;
    var _b = useState(false), isBurgerMenuActive = _b[0], setisBurgerMenuActive = _b[1];
    var name = content.name, homeRoute = content.homeRoute, pages = content.pages;
    var navBarPages = pages.filter(function (page) { return page.includeInMainNav; });
    var toggleBurgerMenu = function () {
        setisBurgerMenuActive(!isBurgerMenuActive);
    };
    var handleLogoMenuItemClick = function () {
        if (isBurgerMenuActive) {
            toggleBurgerMenu();
        }
    };
    return (_jsxs("nav", __assign({ className: "navbar ".concat(fixed ? 'is-fixed-top' : ''), role: "navigation", "aria-label": "main navigation" }, { children: [_jsxs("div", __assign({ className: "navbar-brand" }, { children: [_jsx(Link, __assign({ to: homeRoute, onClick: handleLogoMenuItemClick, className: "navbar-item" }, { children: _jsx("b", { children: name }) })), _jsx("a", __assign({ role: "button", className: "navbar-burger", "aria-label": "menu", "aria-expanded": "false", "data-target": "navbarBasic", onClick: toggleBurgerMenu }, { children: _jsxs("div", __assign({ className: "burger-icon".concat(isBurgerMenuActive ? '-is-active' : '') }, { children: [_jsx("span", { "aria-hidden": "true" }), _jsx("span", { "aria-hidden": "true" }), _jsx("span", { "aria-hidden": "true" })] })) }))] })), _jsx("div", __assign({ id: "navbarBasic", className: "navbar-menu ".concat(isBurgerMenuActive ? 'is-active' : '') }, { children: _jsx("div", __assign({ className: "navbar-start" }, { children: navBarPages.map(function (page, i) {
                        var _a;
                        var hasDropdown = page.type === PageType.SECTIONS;
                        return (_jsxs("div", __assign({ className: "navbar-item".concat(hasDropdown ? ' has-dropdown is-hoverable' : '') }, { children: [_jsx(Link, __assign({ to: "".concat(page.route), onClick: toggleBurgerMenu, className: "".concat(hasDropdown ? 'navbar-link' : 'navbar-item') }, { children: page.title })), hasDropdown && (_jsx("div", __assign({ className: "navbar-dropdown" }, { children: (_a = page.sections) === null || _a === void 0 ? void 0 : _a.map(function (section, j) {
                                        return (_jsx(Link, __assign({ to: "".concat(page.route, "#").concat(section.slug), onClick: toggleBurgerMenu, className: "navbar-item" }, { children: section.title }), "".concat(j, "-").concat(page.route, "-").concat(section.slug)));
                                    }) })))] }), "".concat(i, "-").concat(page.route)));
                    }) })) }))] })));
};

export var PageType;
(function (PageType) {
    PageType["SECTIONS"] = "sections";
    PageType["CALCULATOR"] = "calculator";
    PageType["FORM"] = "form";
})(PageType || (PageType = {}));
export var CalculatorType;
(function (CalculatorType) {
    CalculatorType["PLATFORM_FEES"] = "platform_fees";
    CalculatorType["COMPOUND_INTEREST"] = "compound_interest";
})(CalculatorType || (CalculatorType = {}));
export var ScreenType;
(function (ScreenType) {
    ScreenType["LISTS"] = "lists";
    ScreenType["TEXT"] = "text";
    ScreenType["TILE"] = "tile";
    ScreenType["GRAPH"] = "graph";
})(ScreenType || (ScreenType = {}));
export var routes = {
    ROOT: '/',
    HOME: '/home',
    COMPOUND_INTEREST: '/compound-interest',
    PLATFORM_FEES: '/platform-fees',
    FEEDBACK: '/feedback',
    FORM_SUCCESS: '?form=success',
    NOT_FOUND: '/404',
};
export var content = {
    name: 'My Money UK',
    homeRoute: routes.HOME,
    config: {
        isNavBarFixed: true,
        routes: routes,
        footerLinks: [
            {
                name: 'Got feedback? Let us know!',
                url: routes.FEEDBACK,
            },
        ],
    },
    socials: [
        {
            name: 'Instagram',
            url: 'https://instagram.com/mymoneyuk',
        },
        {
            name: 'Twitter',
            url: 'https://twitter.com/ukmymoney',
        },
        {
            name: 'Threads',
            url: 'https://threads.net/@mymoneyuk',
        },
    ],
    pages: [
        {
            route: routes.HOME,
            title: 'Home',
            includeInMainNav: true,
            description: 'Use our helpful calculators to answer your money questions.',
            type: PageType.SECTIONS,
            hero: {
                headline: 'Make your money work for you',
                description: 'We have various tools which help you make the most of your investments. Try our broker fee comparison tool below.',
                ctaText: 'Compare fees',
                ctaUrl: "".concat(routes.PLATFORM_FEES),
            },
            sections: [
                {
                    slug: 'calculators',
                    title: 'Money calculators',
                    type: ScreenType.TILE,
                    summary: '',
                    tiles: [
                        {
                            title: 'Compound Interest Calculator',
                            slug: 'compound-interest-calculator',
                            ctaText: 'Try it out',
                            ctaUrl: routes.COMPOUND_INTEREST,
                            description: 'Find out what your savings will be worth.',
                        },
                        {
                            title: 'Compare Platform Fees',
                            slug: 'compare-platform-fees',
                            ctaText: 'Try it out',
                            ctaUrl: routes.PLATFORM_FEES,
                            description: "Find out how much you'll pay in platform fees for your investments.",
                        },
                    ],
                },
            ],
        },
        {
            type: PageType.CALCULATOR,
            title: 'Compound Interest Calculator',
            includeInMainNav: true,
            calculatorType: CalculatorType.COMPOUND_INTEREST,
            description: 'Find out how much your savings could be worth',
            route: routes.COMPOUND_INTEREST,
            topSection: {
                title: 'Compound Interest Calculator',
                description: 'Use this helpful tool to calculate how much your savings could be worth with the snowball effect of compound interest. Input either a one-off investment, or a regular monthly amount, or both.',
                slug: '',
                summary: '',
                type: ScreenType.TEXT,
            },
        },
        {
            type: PageType.CALCULATOR,
            title: 'Platform Fees Calculator',
            includeInMainNav: true,
            calculatorType: CalculatorType.PLATFORM_FEES,
            description: 'Compare platform fees.',
            route: routes.PLATFORM_FEES,
            topSection: {
                title: 'Platform Fees Calculator',
                description: 'Find out how much you can expect to pay in platform fees with various investment brokers, depending on the amount that you have invested.',
                slug: '',
                summary: '',
                type: ScreenType.TEXT,
            },
        },
        {
            type: PageType.FORM,
            title: 'Suggestions or feedback? Get in touch!',
            includeInMainNav: false,
            description: 'If you have any suggestions for new features or would like to provide feedback, then please get in touch by filling out the form below.',
            route: routes.FEEDBACK,
        },
    ],
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { IconInstagram, IconTwitter } from '../atoms';
import { content } from '../../content';
import { IconThreads } from '../atoms/IconThreads';
export var SocialLinks = function (_a) {
    var size = _a.size;
    var socials = content.socials;
    var hasSocialLinks = socials.length > 0;
    var renderSocialLink = function (social) {
        switch (social.name) {
            case 'Instagram':
                return _jsx(IconInstagram, { size: size, url: social.url });
            case 'Twitter':
                return _jsx(IconTwitter, { size: size, url: social.url });
            case 'Threads':
                return _jsx(IconThreads, { size: size, url: social.url });
            default:
                return _jsx(_Fragment, {});
        }
    };
    return (_jsx("div", __assign({ className: "social-container" }, { children: hasSocialLinks && (_jsx("div", __assign({ className: "columns is-mobile is-gapless" }, { children: socials.map(function (social, index) { return (_jsx("div", __assign({ className: "column" }, { children: renderSocialLink(social) }), "".concat(index, "-").concat(social.name))); }) }))) })));
};

import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CalculatorType } from '../../content';
import { CalculatorCompoundInterest } from './CalculatorCompoundInterest';
import { CalculatorPlatformFees } from './CalculatorPlatformFees';
export var CalculatorContainer = function (_a) {
    var type = _a.type;
    var renderCalculator = function (type) {
        switch (type) {
            case CalculatorType.PLATFORM_FEES:
                return _jsx(CalculatorPlatformFees, { title: 'Compare Platform Fees' });
            case CalculatorType.COMPOUND_INTEREST:
                return (_jsx(CalculatorCompoundInterest, { title: 'Calculate Compound Interest' }));
            default:
                return _jsx(_Fragment, {});
        }
    };
    return _jsx(_Fragment, { children: renderCalculator(type) });
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Helmet } from '../molecules';
import { content } from '../../content';
import { CalculatorContainer } from '../organisms/CalculatorContainer';
export var PageCalculator = function (_a) {
    var page = _a.page;
    var name = content.name;
    var title = page.title, description = page.description, calculatorType = page.calculatorType;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { title: "".concat(title, " | ").concat(name), description: description }), _jsx(CalculatorContainer, { type: calculatorType })] }));
};

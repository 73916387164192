var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
export var EnquiryFormSuccessModal = function (_a) {
    var isActive = _a.isActive, toggleIsActive = _a.toggleIsActive;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "modal".concat(isActive ? ' is-active' : '') }, { children: [_jsx("div", { className: "modal-background", onClick: toggleIsActive }), _jsx("div", __assign({ className: "modal-content" }, { children: _jsxs("article", __assign({ className: "message" }, { children: [_jsxs("div", __assign({ className: "message-header" }, { children: [_jsx("p", { children: "Thank you for your message" }), _jsx("button", { className: "delete", "aria-label": "close", onClick: toggleIsActive })] })), _jsx("div", __assign({ className: "message-body" }, { children: "We really appreciate the feedback!" }))] })) }))] })) }));
};

import { jsx as _jsx } from "react/jsx-runtime";
import { PageType } from '../../content';
import { PageBase } from './PageBase';
import { PageCalculator } from './PageCalculator';
import { PageForm } from './PageForm';
import { PageSections } from './PageSections';
export var PageContainer = function (_a) {
    var page = _a.page;
    var renderPage = function (page) {
        switch (page.type) {
            case PageType.SECTIONS:
                return _jsx(PageSections, { page: page });
            case PageType.CALCULATOR:
                return _jsx(PageCalculator, { page: page });
            case PageType.FORM:
                return _jsx(PageForm, { page: page });
            default:
                return _jsx(PageBase, { page: page });
        }
    };
    return _jsx("div", { children: renderPage(page) });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { EnquiryForm, Helmet } from '../molecules';
import { content } from '../../content';
import { EnquiryFormSuccessModalContainer } from '../organisms';
import { useLocation } from 'react-router-dom';
export var PageForm = function (_a) {
    var page = _a.page;
    var search = useLocation().search;
    var name = content.name, FORM_SUCCESS = content.config.routes.FORM_SUCCESS;
    var title = page.title, description = page.description;
    var randNum1 = Math.ceil(Math.random() * 10);
    var randNum2 = Math.ceil(Math.random() * 10);
    var captcha = {
        randNum1: randNum1,
        randNum2: randNum2,
        correctSum: randNum1 + randNum2,
    };
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { title: "".concat(title, " | ").concat(name), description: description }), _jsx(EnquiryFormSuccessModalContainer, { isActive: search === FORM_SUCCESS }), _jsx("div", __assign({ className: "hero-body" }, { children: _jsxs("div", __assign({ className: "container has-text-centered" }, { children: [_jsx("p", __assign({ className: "title" }, { children: title })), _jsx("div", __assign({ className: "feedback-form" }, { children: _jsx(EnquiryForm, { successUrl: FORM_SUCCESS, captcha: captcha }) }))] })) }))] }));
};

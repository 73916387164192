var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tiles } from './Tiles';
export var SectionTiles = function (_a) {
    var tileSection = _a.tileSection;
    var title = tileSection.title, tiles = tileSection.tiles;
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "hero-body" }, { children: _jsxs("div", __assign({ className: "container has-text-centered" }, { children: [_jsx("p", __assign({ className: "title" }, { children: title })), _jsx("div", __assign({ className: "tile is-ancestor" }, { children: _jsx(Tiles, { sectionTitle: title, tiles: tiles }) }))] })) })) }));
};

export var generateArray = function (min, max, length) {
    var arr = [];
    var absoluteLength = Math.abs(length);
    var step = (max - min) / (absoluteLength - 1);
    for (var i = 0; i < absoluteLength; i++) {
        arr.push(min + step * i);
    }
    return arr;
};
export var formatGBPString = function (amount) {
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formatter.format(amount);
};
export var calculateMarginalFee = function (amount, _a) {
    var marginMin = _a.marginMin, marginMax = _a.marginMax, feeDecimal = _a.feeDecimal;
    if (amount < marginMin) {
        return 0;
    }
    if (amount > marginMax) {
        return (marginMax - marginMin) * feeDecimal;
    }
    return (amount - marginMin) * feeDecimal;
};
export var calculateCompoundInterest = function (parameters) {
    var principal = parameters.principal, monthly = parameters.monthly, duration = parameters.duration, rate = parameters.rate;
    var result = principal;
    for (var i = 0; i < duration * 12; i++) {
        result += result * (rate / 12 / 100);
        result += monthly;
    }
    return Math.round(result * 100) / 100;
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes as ReactRouterRoutes, useLocation, } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { content } from './content';
import { Footer, ScrollToTop } from './components/molecules';
import { NavBar } from './components/organisms';
import { PageNotFound } from './components/pages';
import { PageContainer } from './components/pages/PageContainer';
function App() {
    var _a;
    var name = content.name, _b = content.config, isNavBarFixed = _b.isNavBarFixed, _c = _b.routes, HOME = _c.HOME, ROOT = _c.ROOT, NOT_FOUND = _c.NOT_FOUND, footerLinks = _b.footerLinks;
    var location = useLocation();
    useEffect(function () {
        if (isNavBarFixed) {
            var html = window.document.querySelector('html');
            html === null || html === void 0 ? void 0 : html.classList.add('has-navbar-fixed-top');
        }
    }, [isNavBarFixed]);
    useEffect(function () {
        // @ts-ignore
        if (window.goatcounter) {
            // @ts-ignore
            window.goatcounter.count({
                path: location.pathname + location.search + location.hash,
            });
        }
    }, [location]);
    return (_jsxs(_Fragment, { children: [_jsx(NavBar, { fixed: isNavBarFixed, content: content }), _jsx(ScrollToTop, {}), _jsxs(ReactRouterRoutes, { children: [(_a = content === null || content === void 0 ? void 0 : content.pages) === null || _a === void 0 ? void 0 : _a.map(function (page, index) {
                        return (_jsx(Route, { path: page.route, element: _jsx(PageContainer, { page: page }) }, "".concat(index, "-").concat(page.route)));
                    }), _jsx(Route, { path: NOT_FOUND, element: _jsx(PageNotFound, { siteTitle: name }) }), _jsx(Route, { path: ROOT, element: _jsx(Navigate, { to: HOME }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: NOT_FOUND }) })] }), _jsx(Footer, { siteTitle: name, footerLinks: footerLinks })] }));
}
function AppWrapper() {
    return (_jsx(BrowserRouter, { children: _jsx(HelmetProvider, { children: _jsx(App, {}) }) }));
}
export default AppWrapper;

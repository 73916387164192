var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Helmet } from '../molecules/Helmet';
export var PageNotFound = function (_a) {
    var siteTitle = _a.siteTitle;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { title: "404 Not Found | ".concat(siteTitle), description: "About ".concat(siteTitle) }), _jsx("section", __assign({ className: "hero is-danger has-text-centered" }, { children: _jsx("div", __assign({ className: "hero-body" }, { children: _jsx("p", __assign({ className: "title" }, { children: "Oops... Page Not Found..." })) })) }))] }));
};

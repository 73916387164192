var _a, _b;
export var InvestmentType;
(function (InvestmentType) {
    InvestmentType["FUND"] = "fund";
    InvestmentType["SHARE"] = "share";
})(InvestmentType || (InvestmentType = {}));
export var MapInvestmentTypeToLabelString = (_a = {},
    _a[InvestmentType.FUND] = 'fund',
    _a[InvestmentType.SHARE] = 'share',
    _a);
export var InvestmentTypes = [
    InvestmentType.FUND,
    InvestmentType.SHARE,
];
export var WrapperType;
(function (WrapperType) {
    WrapperType["GIA"] = "gia";
    WrapperType["ISA"] = "isa";
    WrapperType["LISA"] = "lisa";
    WrapperType["SIPP"] = "sipp";
})(WrapperType || (WrapperType = {}));
export var MapWrapperTypeToLabelString = (_b = {},
    _b[WrapperType.GIA] = 'General Investing Account',
    _b[WrapperType.ISA] = 'S&S ISA',
    _b[WrapperType.LISA] = 'S&S LISA',
    _b[WrapperType.SIPP] = 'SIPP',
    _b);
export var WrapperTypes = [
    WrapperType.GIA,
    WrapperType.ISA,
    WrapperType.LISA,
    WrapperType.SIPP,
];

import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EnquiryFormSuccessModal } from './EnquiryFormSuccessModal';
import { content } from '../../content';
export var EnquiryFormSuccessModalContainer = function (_a) {
    var isActive = _a.isActive;
    var _b = useState(isActive), isModalActive = _b[0], setIsModalActive = _b[1];
    var _c = content.config.routes, HOME = _c.HOME, FORM_SUCCESS = _c.FORM_SUCCESS;
    var navigate = useNavigate();
    var currentLocation = useLocation();
    var toggleModal = function () {
        setIsModalActive(!isModalActive);
    };
    useEffect(function () {
        if (currentLocation.search === FORM_SUCCESS && !isModalActive) {
            navigate(HOME);
        }
    }, [currentLocation, isModalActive, navigate, FORM_SUCCESS, HOME]);
    return (_jsx(EnquiryFormSuccessModal, { isActive: isActive, toggleIsActive: toggleModal }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { formatGBPString, MapInvestmentTypeToLabelString, MapWrapperTypeToLabelString, } from '@mymoneyuk/shared-utils';
export var FeesSummary = function (_a) {
    var amountInvested = _a.amountInvested, investmentType = _a.investmentType, wrapperType = _a.wrapperType, feesSummary = _a.feesSummary;
    var displayNotes = feesSummary.filter(function (fees) { return fees.note; }).length > 0;
    return (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx("p", __assign({ className: "title" }, { children: "Fees Summary" })), _jsxs("div", { children: ["With ", _jsx("b", { children: formatGBPString(amountInvested || 0) }), " invested in", ' ', _jsxs("b", { children: [MapInvestmentTypeToLabelString[investmentType], "s"] }), ", in a", ' ', _jsx("b", { children: MapWrapperTypeToLabelString[wrapperType] }), ", annual platform fees are:"] }), _jsxs("table", __assign({ className: "workout-view table is-bordered" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Platform" }), _jsx("th", { children: "Annual Fee" }), displayNotes && _jsx("th", { children: "Notes" })] }) }), _jsx("tbody", { children: feesSummary.map(function (fee, index) { return (_jsxs("tr", { children: [_jsx("td", { children: fee.name }), _jsx("td", { children: formatGBPString(fee.annualFee) }), displayNotes && _jsx("td", { children: fee.note || '' })] }, "".concat(index, "-").concat(fee.name))); }) })] }))] }));
};
